import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class WebSearchOptimization extends Component{
    constructor () {
             super()
             this.state = {
               isOpen: false
             }
             this.openModal = this.openModal.bind(this)
         }
         openModal () {
             this.setState({isOpen: true})
         }

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Web Search Optimization Services' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">WEB SEARCH OPTIMIZATION SOLUTIONS</h2>
                                    <p>Enhancing Visibility and Growth with Vikacode</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/seo.png" alt="SEO Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Vikacode specializes in web search optimization techniques that propel your website to the top of search results, driving organic traffic and growth.</p>
                                                    <p>We combine technical prowess with content strategies to ensure your website ranks for the right keywords and reaches your target audience.</p>
                                                    <h4 className="title">Our SEO Approach</h4>
                                                    <ul className="liststyle">
                                                        <li>Website Audit & Analysis</li>
                                                        <li>On-page Optimization</li>
                                                        <li>Off-page Link Building</li>
                                                        <li>Content Strategy & Optimization</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Search optimization isn't just about algorithms; it's about understanding user intent and delivering value. We ensure your website not only ranks high but also offers genuine value to visitors.</p>
                                                    <p>With regular reports and analytics, we keep you in the loop, adjusting strategies to ever-changing search engine algorithms.</p>
                                                    <h4 className="title">Why Vikacode for SEO?</h4>
                                                    <ul className="liststyle">
                                                        <li>Proven Track Record of Success</li>
                                                        <li>Transparent Reporting</li>
                                                        <li>Dedicated SEO Experts</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/seo2.png" alt="SEO Strategy Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>

                <Footer />
            </React.Fragment>
        )
    }
}
export default WebSearchOptimization;
