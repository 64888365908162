import React from "react";
import { Helmet } from 'react-helmet';

const PageHelmet = () => {
    return (
        <>
            <Helmet>
                <title>Vikacodes</title>
                <meta name="description"
                 content="Vikacodes | Leading Digital Solutions Provider.
                  Specializing in web developments, digital campaigns, and software solutions."/>
                    <meta property="og:url" content="pets.abc" />
                    <meta property="og:site_name" content="Pets - Products" />
                    <meta property="og:locale" content="en_US" />
            </Helmet>
        </>
    );
}

export default PageHelmet;