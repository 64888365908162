
import React, { Component } from "react";
import {FaDatabase,FaLaptopCode,FaMagic, FaUsers, FaBriefcase,FaCubes,FaShieldAlt,FaWifi,FaChartLine} from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom";


const ServiceList = [
    {
        icon: <FaLaptopCode />,
        title: 'Software Development',
        description: 'Crafting tailored software solutions to propel your business into the digital age.',
        // link: '/service-details'
    },
    {
        icon: <FaMagic />,
        title: 'Brand Development',
        description: 'Sculpting brand identities at Vikacode that not only stand out but soar above, defining market leadership.',
        // link: '/ServiceBrandDesign'
    },
    {
        icon: <FaChartLine />,
        title: 'Marketing Strategies',
        description: 'Designing and implementing digital marketing strategies to tower above competitors in the online arena.',
        // link: '/ServiceMarketing'
    },
    {
        icon: <FaDatabase />,
        title: 'Data Analysis and Insights',
        description: 'Leveraging data to provide insights and strategies, guiding your business straight to the summit of success.',
        // link: '/ServiceDataAnalysis'
    },
    {
        icon: <FaUsers />,
        title: 'Public Relations and Engagement',
        description: 'Fostering public relations and engagement to enhance your brand presence and connection with the audience.',
        // link: '/ServicePublicRelations'
    },
    {
        icon: <FaBriefcase />,
        title: 'Consultancy and Strategies',
        description: 'Providing expert consultancy and strategies to navigate the complexities of the digital landscape.',
        // link: '/ServiceConsultancy'
    },
    {
        icon: <FaCubes />,
        title: 'Blockchain',
        description: 'Implementing cutting-edge blockchain solutions for secure and transparent transactions in the digital realm.',
        // link: '/ServiceBlockchain'
    },
    {
        icon: <FaWifi />,
        title: 'Internet of Things (IoT)',
        description: 'Delivering innovative solutions and expertise in the field of IoT to enhance connectivity and automation.',
        // link: '/ServiceIoT'
    },
    {
        icon: <FaShieldAlt />,
        title: 'Cyber Security',
        description: 'Ensuring robust security measures to protect against cyber threats and safeguard sensitive information.',
        // link: '/ServiceCyberSecurity'
    }
];

class ServiceThree extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                        <Link to={val.link}>
    <div className="service service__style--2">
        <div className="icon">
            {val.icon}
        </div>
        <div className="content">
            <h3 className="title">{val.title}</h3>
            <p>{val.description}</p>
        </div>
    </div>
</Link>

                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}

export default ServiceThree;



