import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import FooterTwo from "../component/footer/FooterTwo"
const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Contact with Vikacodes' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17" data-black-overlay="3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 style ={{color:"blue"}} >Contact us</h2>
                                    <p>Reach out to us and let's bring your vision to life together.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact with Phone Number</h4>
                                        <p><a href="tel:+255626494290">+255 626 494 290</a></p>
                                        <p><a href="tel:+255782859613">+255 782 859 613</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email Address</h4>
                                        <p><a href="mailto:admin@vikacodes.com">service@vikacodes.co.tz</a></p>
                                        <p><a href="mailto:info@vikacodes.com">info@vikacodes.co.tz</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Location</h4>
                                        <p>Vikacodes Office 
                                            <br/>Arusha and 
                                    Dodoma </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '150%', width: '100%' }}>
                        {/* <GoogleMapReact
                            defaultCenter={this.props.center}
                            defaultZoom={this.props.zoom}
                        >
                            <AnyReactComponent
                                lat={59.955413}
                                lng={30.337844}
                                text="Vikacodes Location"
                            />
                        </GoogleMapReact> */}
                         <ContactTwo/>
                    </div>
                </div>

                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>         
                </div>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>  
                <div >
                    <div>
                        <div className="row">
                            <div className="col-lg-12">
                                <FooterTwo />
                            </div>
                        </div>
                    </div>         
                </div>            
            </React.Fragment>
        )
    }
}
export default Contact;
