import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class ServiceBrandDesign extends Component{
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Brand & Design Services' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">BRAND & DESIGN SOLUTIONS</h2>
                                    <p>Creating Visual Masterpieces with Vikacode</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-brand-design-01.png" alt="Brand Design Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>At Vikacode, we understand the power of branding and its impact on your business. We craft unique, timeless brands that resonate with your audience and stand out in the market.</p>
                                                    <p>From logo designs to comprehensive branding strategies, we ensure your brand tells a consistent, compelling story.</p>
                                                    <h4 className="title">Our Branding Approach</h4>
                                                    <ul className="liststyle">
                                                        <li>Discovery & Research</li>
                                                        <li>Visual Identity Creation</li>
                                                        <li>Brand Strategy & Positioning</li>
                                                        <li>Design Implementation</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>A brand is not just a logo; it's an experience. Our holistic approach ensures every touchpoint of your brand, from stationery to advertising, reflects your business's essence.</p>
                                                    <p>We strive to build brands that inspire trust, loyalty, and admiration from your target audience.</p>
                                                    <h4 className="title">Why Vikacode for Branding?</h4>
                                                    <ul className="liststyle">
                                                        <li>Passion for Design Excellence</li>
                                                        <li>Strategic Brand Building</li>
                                                        <li>Personalized Approach</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-brand-design-2.png" alt="Branding Strategy Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>

                <Footer />
            </React.Fragment>
        )
    }
}
export default ServiceBrandDesign;
