import React, { Component } from "react";
import { MdWeb, MdBrush, MdPhoneAndroid} from "react-icons/md";

const TeamContent = [
    {
        images: '01',
        title: 'vicent kamuhabwa',
        designation: 'Software Engineer',
        icon: <MdWeb size={50} />
    },
    {
        images: '02',
        title: 'Pius Kishiki',
        designation: 'Mobile app developer',
        icon: <MdPhoneAndroid size={50} />
    },
    {
        images: '03',
        title: 'Hadija Hassan',
        designation: 'Graphic Designer',
        icon: <MdBrush size={50} />
    },
    {
        images: '06',
        title: 'Rajabu Eliud',
        designation: 'Full-stack developer',
        icon: <MdPhoneAndroid size={50} />
    }
];

class Team extends Component {
    state = {
        currentMemberIndex: 0,
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            if (this.state.currentMemberIndex < TeamContent.length - 1) {
                this.setState((prevState) => ({
                    currentMemberIndex: prevState.currentMemberIndex + 1,
                }));
            } else {
                this.setState({ currentMemberIndex: 0 });
            }
        }, 3000); // Change every 3 seconds
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const activeMember = TeamContent[this.state.currentMemberIndex];

        return (
            <div
                className="team-member-showcase"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "400px",
                    marginBottom: "50px",
                    position: "relative",
                    width: "100%",
                    background:
                        "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)", // A subtle gradient background
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "20px",
                        borderRadius: "8px",
                        backgroundColor: "#fff", // White background
                        boxShadow: "0px 10px 30px -5px rgba(0, 0, 0, 0.3)", // Subtle shadow for elevation effect
                        transition: "all 0.3s", // Smooth transition for any changes
                        maxWidth: "300px",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "20px",
                            padding: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#f0f0f0", // Light gray background for the icon container
                        }}
                    >
                        {activeMember.icon}
                    </div>
                    <h3
                        style={{
                            marginBottom: "10px",
                            fontWeight: "bold", // Bolder weight for the title
                            fontSize: "24px", // Slightly larger font
                            letterSpacing: "1px", // Slight letter spacing for better readability
                            color: "#333",
                        }}
                    >
                        {activeMember.title}
                    </h3>
                    <p
                        style={{
                            fontStyle: "italic",
                            color: "#555",
                            fontWeight: "500", // Medium weight for the designation
                            letterSpacing: "0.5px", // Slight letter spacing
                        }}
                    >
                        {activeMember.designation}
                    </p>
                </div>
            </div>
        );
    }
}

export default Team;
