import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Your project details have been successfully received. We will contact you soon to discuss collaboration.</p>
    )
}

function WorkWithUsForm({ props }) {
    const [result, showResult] = useState(false);
    const formRef = useRef(null);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_t85smak',
                'template_9h90ln6',
                formRef.current, // Passing the form element
                'RqLXinhaHPhynyeow'
            )
            .then((result) => {
                console.log(result.text);
            })
            .catch((error) => {
                console.log(error.text);
            });

        e.target.reset();
        showResult(true);

        setTimeout(() => {
            showResult(false);
        }, 5000);
    };

    return (
        <form ref={formRef} action="" onSubmit={sendEmail} encType="multipart/form-data">
         <div className="rn-form-group"> 
          <input 
                type="text"
                name="business_name"
                placeholder="Full Name"
                required
                />
            </div>

            <div className="rn-form-group">
                           <input   type="email"
                name="business_email"
                placeholder=" Email Address"
                required
                />
            </div>
            <div className="rn-form-group">
                           <input   type="tel"
                name="phone_number"
                placeholder=" Phone number"
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="text"
                name="organisation"
                placeholder="Bussiness /Organisation"
                required
                />
            </div>
            
            <div className="rn-form-group">
                <textarea 
                name="project_details"
                placeholder="Describe the Project"
                required
                >
                </textarea>
            </div>
            
            <div className="rn-form-group">
                <label htmlFor="project_file">+Attach your brief </label>
                <input 
                type="file"
                name="project_file"
                id="project_file"
                
                />
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit_project" name="submit_project" id="mc-embedded-submit">Submit Project</button>
            </div> 

            <div className="rn-form-group">   
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}

export default WorkWithUsForm;
