import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class Testimonial extends Component {
    constructor() {
        super();
        this.state = {
            tabIndex: 0
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState(prevState => {
                if (prevState.tabIndex === 2) { // 2 because you have 3 testimonials (0, 1, 2)
                    return { tabIndex: 0 };
                }
                return { tabIndex: prevState.tabIndex + 1 };
            });
        }, 5000); // change every 5 seconds
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>From a dream to reality, our journey has been a beacon of innovation and dedication. We're not just setting standards; we're redefining them.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Kamuhabwa Vicent</span> - Founder & CEO, Vikacodes </h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Every process, every decision, is a blend of precision and passion. Excellence isn't our goal; it's our starting point.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Bernadetha Poul </span> - COO, Vikacodes</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Our team's drive has been our compass, guiding us to not just meet but elevate every expectation. Together, we shape tomorrow.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span> Wilium Masawe </span> - Managing Director, Vikacodes</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabList>
                                {/* This is left empty intentionally since you want to remove the images but keep a mechanism for transitioning between testimonials. */}
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;
