import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";

class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Mobile Application Services' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">MOBILE APPLICATION SOLUTIONS</h2>
                                    <p>Exceptional Mobile Experiences Crafted by Vikacode</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-09.jpg " alt="App Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Vikacode excels in creating mobile applications that resonate with users and drive engagement. Our tailored solutions seamlessly translate your vision into pocket-sized realities.</p>
                                                    <p>We craft mobile apps that are intuitive, fast, and visually stunning, ensuring an unmatched user experience.</p>
                                                    <h4 className="title">Our App Development Process</h4>
                                                    <ul className="liststyle">
                                                        <li>Conceptualization & Planning</li>
                                                        <li>Design & User Experience</li>
                                                        <li>Development & Testing</li>
                                                        <li>Launch & Post-Launch Support</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>We adopt the latest in mobile technologies and methodologies, bringing to your audience an app that's ahead of the curve.</p>
                                                    <p>From native to cross-platform solutions, Vikacode ensures your mobile presence stands out.</p>
                                                    <h4 className="title">Why Vikacode for Mobile Apps?</h4>
                                                    <ul className="liststyle">
                                                        <li>Deep Technical Expertise</li>
                                                        <li>User-Centric Design Philosophy</li>
                                                        <li>Commitment to Quality & Performance</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceDetails;
