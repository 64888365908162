import React, { Component } from "react";
import WorkWithUsForm from "../service/WorkWithUs";
import FooterTwo from "../../component/footer/FooterTwo";

class ContactTwo extends Component {
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                        <div className="section-title text-left mb--50">
    <h3 className="title">Work with US</h3>
    <p className="description">We transform digital ideas into impactful solutions. Let's innovate, collaborate, and redefine what's possible together.</p>
</div>

                            <div className="form-wrapper">
                                <WorkWithUsForm />
                            </div>
                          
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <img src="/assets/images/about/about-6.jpg" alt="vikacodes"/>
                            </div>
                        </div>                       
                    </div>
                    
                </div>
            </div>
        )
    }
}
export default ContactTwo;