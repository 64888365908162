import React, { Component } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        });
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open');
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    render() {
        const { logo } = this.props;
        let logoUrl;

        switch (logo) {
            case 'light':
                logoUrl = "/assets/images/logo/logo-light.png";
                break;
            case 'dark':
                logoUrl = "/assets/images/logo/logo-dark.png";
                break;
            case 'symbol-dark':
                logoUrl = "/assets/images/logo/logo-symbol-dark.png";
                break;
            case 'symbol-light':
                logoUrl = "/assets/images/logo/logo-symbol-light.png";
                break;
            default:
                logoUrl = "/assets/images/logo/logo.png";
                break;
        }

        return (
            <header className="header-area formobile-menu header--fixed default-color">
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                <img className="logo-1" src={logoUrl} alt="Logo Images"/>
                                <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li><Link to="/partical#Home">Home</Link></li>
                                <li><Link to="/partical#service">Service</Link></li>
                                <li><Link to="/partical#about">About</Link></li>
                                <li><Link to="/partical#portfolio">Portfolio</Link></li>
                                <li><Link to="/partical#team">Team</Link></li>
                                <li><Link to="/partical#testimonial">Testimonial</Link></li>
                                <li><Link to="/partical#contact">Contact</Link></li>
                            </ul>
                        </nav>
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
