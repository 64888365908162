import React, { Component , Fragment } from "react";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import Particles from "react-tsparticles";
import { FiChevronUp , FiX , FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Team from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";

const SlideList = [
    {
        textPosition: 'text-center',
        category:'',
        title:'vika codes',
        description:'Soaring Above Standards, our mission is to elevate your business to its pinnacle. With the power of digital innovation, we aim to lift businesses to the peak of success. Let us ascend together.',
        buttonText:'Contact Us',
        buttonLink:'/contact'
    }
]
const list = [
    {
        image: 'image-1',
        category: 'Education',
        title: 'Enhanced e-learning platforms for remote education, impacting over 10,000 students.'
    },
    {
        image: 'image-2',
        category: 'Healthcare',
        title: 'Developed telemedicine apps, bridging the gap between patients and doctors during the pandemic.'
    },
    {
        image: 'image-3',
        category: 'E-Commerce',
        title: 'Boosted online sales for local businesses with intuitive e-commerce solutions, driving 50% revenue increase.'
    },
    {
        image: 'image-5',
        category: 'Hospitality',
        title: 'Implemented AI-driven booking systems for hotels, enhancing user experience and increasing bookings.'
    },
    {
        image: 'image-4',
        category: 'Agriculture',
        title: 'Digitalized farming analytics, aiding farmers in crop predictions and increasing yield by 20%.'
    },
]

class HomeParticles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSticky: false
        }
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    }
    
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        });
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll = () => {
        var value = window.scrollY;
        if (value > 100) {
            this.setState({ isSticky: true });
        } else {
            this.setState({ isSticky: false });
        }
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open');
    }
    
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    
    render() {
        const { isSticky } = this.state;
        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        return(
            <Fragment>
                <Helmet pageTitle="Home Particles" />

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                 <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/> 
                                </a>
                            </div>
                        </div>
                        <div className="header-right" >
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','service', 'about', 'portfolio','team','testimonial', 'contact']} currentClassName="is-current" offset={-200}>
                                    <li><a href="#home" style={{Color: 'blue' }}>Home</a></li>
                                    <li><a href="#service" style={{Color: 'blue' }}>Service</a></li>
                                    <li><a href="#about" style={{Color: 'blue' }}>About</a></li>
                                    <li><a href="#portfolio" style={{Color: 'blue' }}>Portfolio</a></li>
                                    <li><a href="#team" style={{Color: 'blue' }}>Team</a></li>
                                    <li><a href="#testimonial"style={{Color: 'blue' }}>Testimonial</a></li>
                                    {/* <li><a href="#blog">Blog</a></li> */}
                                    <li><a href="#contact">Contact</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-black"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}
{/* Start Slider Area   */}
                
              <div className="slider-activation slider-creative-agency with-particles" id="home">
                    <div className="frame-layout__particles">
                        <Particles className="particle"  
                            options={{
                                style:{
                                    position: "absolute"
                                },
                                fpsLimit: 100,
                                interactivity: {
                                detectsOn: "canvas",
                                events: {
                                    onClick: {
                                    enable: true,
                                    mode: "push",
                                    },
                                    onHover: {
                                    enable: true,
                                    mode: "repulse",
                                    },
                                    resize: true,
                                },
                                modes: {
                                    bubble: {
                                        distance: 100,
                                        duration: 2,
                                        opacity: 0.8,
                                        size: 10,
                                        color: "#888",
                                    },
                                    push: {
                                    quantity: 4,
                                    },
                                    repulse: {
                                        distance: 100,
                                        duration: 0.4,
                                        color: "#888",
                                    },
                                },
                                },
                                particles: {
                                color: {
                                    value: "#865",
                                },
                                links: {
                                    color: "#888",
                                    distance: 150,
                                    enable: true,
                                    opacity: 0.5,
                                    width: 1,
                                },
                                collisions: {
                                    enable: true,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outMode: "bounce",
                                    random: false,
                                    speed: 6,
                                    straight: false,
                                   
                                },
                                number: {
                                    density: {
                                        enable: true,
                                        value_area: 2000,
                                    },
                                    value: 80,
                                },
                                opacity: {
                                    value: 0.5,
                                },
                                shape: {
                                    type: "circle",
                                },
                                size: {
                                    random: true,
                                    value: 5,
                                },
                                },
                                detectRetina: true,
                            }}
                        />
                    </div>
                    <div className="bg_image bg_image--27" style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)'} }>
                            {SlideList.map((value , index) => (
                                <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                    <div className="container">
                                        <div className="row">
                                        <div className="col-lg-6">
                                        <div className={`inner ${value.textPosition}`} 
               style={{ backgroundColor: '#ffffff', padding: '20px', borderRadius: '10px', opacity:0.9, height:'100%'}}>
                     {value.category && <span>{value.category}</span>}                                                                                                              
                     {value.title && <h2 className="title" style={{color:"blue"}}>{value.title}</h2>}
                     {value.description && <p className="description" style={{color: 'black'}}>{value.description}</p>}
                     {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                    
                                              </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                </div>
//                 {/* End Slider Area   */}
                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="service">
                    <div className="container">
            <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-left mb--30">
                                    <h3>Our services</h3>
                                    <div>
                                    <p>At Vikacode, we're more than just 
                                        developers—we are digital artisans. 
                                        Each service we offer is tailored to 
                                        your unique needs, merging innovation with
                                         efficiency. From captivating web designs to 
                                         powerful software solutions, we mold our 
                                         expertise around your vision, crafting success 
                                         for every endeavor.</p>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="9" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */}
                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--5" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h3 className="title">About Us</h3>
                                            <p className="description">In the ever-evolving tapestry of the digital age, Vikacode was conceived with a singular, unwavering vision: to breathe life into business dreams through technology. We recognize that every ambition, no matter how vast, can be anchored and elevated with the right technological prowess. At Vikacode, we don’t merely offer solutions; we forge pathways, transforming aspirations into tangible realities and propelling businesses to their zenith. Dive into our narrative and see how we're making dreams come true, one pinnacle at a time.
                                        </p></div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Mission</h3>
                                                    <p>To harness the transformative power of technology, translating business aspirations into innovative solutions, and empowering enterprises to achieve their highest potential.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">vision</h3>
                                                    <p>A world where every business dream, regardless of its scale, finds its realization in technology, with Vikacode leading the way to new heights of success.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pt--120 pb--140 bg_color--1" id="portfolio">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                        <h3 className="title">Our impact</h3>
                                        <p>Explore Vikacode's curated showcase of digital mastery. From web developments to dynamic digital campaigns, witness our commitment to turning business dreams into technological triumphs. Each project stands as a testament to our dedication in elevating businesses to their peak.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {list.map((value , index) => (
                                                <div className="portfolio" key={index}>
                                                    <div className="thumbnail-inner">
                                                        <div className={`thumbnail ${value.image}`}></div>
                                                        <div className={`bg-blr-image ${value.image}`}></div>
                                                    </div>
                                                    <div className="content">
                                                        <div className="inner">
                                                            <h3 style={{color:'white'}}>{value.category}</h3>
                                                            <h6 style = {{color:'white'}}>{value.title}</h6>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--140 p pb--110 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_color--1" id="team">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h3 className="title">Skillful Team</h3>
 <p>Meet the heartbeat of Vikacode – our Skillful Squad. This ensemble of digital artisans is dedicated to transforming ambitions into tangible successes. With a blend of expertise, innovation, and passion, they drive every project to its zenith. When you choose Vikacode, you're not just opting for services; you're aligning with a team that's poised to elevate your business dreams into celebrated realities</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120" id="testimonial">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}
                

                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    <Contact />
                </div>
                {/* End Contact Us */}
                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}
export default HomeParticles;




